import { gerarIde } from "./gerarIde";
import { gerarEmit } from "./gerarEmit";
import { gerarDest } from "./gerarDest";
import { gerarDet } from "./gerarDet";
import { gerarIcmsTot } from "./gerarIcmsTot";
import { gerarTransp } from "./gerarTransp";
import { gerarDetPag } from "./gerarDetPag";
import { gerarInfAdic } from "./gerarInfAdic";
import { buscarEmpresa } from "./funcoes/buscarEmpresa";
import { buscarCliente } from "./funcoes/buscarCliente";
import { Configuracao } from "../../../configuracao";
import { gerarTotalizadorItens } from "./funcoes/gerarTotalizadorItens";
import { calcularTotalizadorItens } from "./funcoes/calcularTotalizadorItens";
import { gerarPag } from "./gerarPag";

export const geraJsonNota = async (jsonNota, operacao, response) => {
  
  if (operacao !== 'ADICIONAR' && operacao !== 'ALTERAR' && operacao !== 'CONVERTER' && operacao !== 'DEVOLVER') {
    return {status: 400, message: 'Informe a operação ADICIONAR, ALTERAR, CONVERTER ou DEVOLVER'};
  }

  const operacaoOpcao = {
    adicionar: operacao === 'ADICIONAR',
    alterar: operacao === 'ALTERAR',
    converter: operacao === 'CONVERTER' || (operacao === 'DEVOLVER' && jsonNota.ide.mod === "65"),
    devolver: operacao === 'DEVOLVER' || (operacao === 'ALTERAR' && jsonNota.ide.finNFe === "4")
  }

  let dest = undefined

  // Buscar os dados da primeira empresa  
  const empresa = await buscarEmpresa();

  if (empresa?.endereco?.municipio?.estado?.uf === "MS" && operacaoOpcao.converter) {
    return {status: 405, message: `${empresa?.endereco?.municipio?.estado?.nome} não permite esta operação.`};
  }  

  const cliente = jsonNota.dest && !operacaoOpcao.adicionar && jsonNota.ide.mod === "55" ? await buscarCliente(jsonNota.dest) : jsonNota.dest;

  if (cliente) {dest = gerarDest(operacaoOpcao, cliente)} 

  const interestadual = empresa && cliente ? (cliente?.endereco?.uf !== undefined && 
                                              empresa?.endereco?.municipio?.estado?.uf !== undefined && 
                                              cliente?.endereco?.uf !== empresa?.endereco?.municipio?.estado?.uf ? true : false) : false;

  const ide = await gerarIde(operacaoOpcao, jsonNota.ide, empresa, jsonNota.dest, jsonNota.chave, interestadual)  

  const emit = gerarEmit(operacaoOpcao, 
                         empresa, 
                         operacaoOpcao.alterar ? jsonNota.emit.id : undefined, 
                         operacaoOpcao.alterar ? jsonNota.emit.enderEmit.id : undefined)
  
  const detalhesProdutos = await gerarDet(operacaoOpcao, jsonNota.id ? jsonNota.det : jsonNota.item, ide, dest, interestadual)    
  const det = detalhesProdutos.det
  
  const icmsTot = gerarIcmsTot(operacaoOpcao, 
    operacaoOpcao.devolver ?  gerarTotalizadorItens(jsonNota, jsonNota.icmsTot.valorDesconto ? jsonNota.icmsTot.valorDesconto : 0, calcularTotalizadorItens(detalhesProdutos.det), false).icmsTot : jsonNota.icmsTot,
    detalhesProdutos.valorTotalImpostoAproximado)

  const transp = gerarTransp(operacaoOpcao, jsonNota.transp)
  const pag = gerarPag(operacaoOpcao, jsonNota.pag)
  const detPag = gerarDetPag(operacaoOpcao, jsonNota.id ? jsonNota.detPag : jsonNota.pagamento)  
  const infAdic = gerarInfAdic(operacaoOpcao, jsonNota.infAdic, detalhesProdutos.observacaoTributos)  

  const nota = {
    empresaId: empresa.id,    
    versao: Configuracao.VERSAO_NFCE,
    ide,
    emit,
    dest,
    det,
    icmsTot,
    transp,
    pag,
    detPag,
    infAdic
  }

  return {status: 200, nota};
}